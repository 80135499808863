export const h = {
    // quick: '快速登录瓜子加速器',
    quick: '快速登录Guazi Routing',
    pleasuser: '请输入用户名',
    pleasword: '请输入密码',
    usererror: '用户名输入错误',
    worderror: '密码长度在8-20位，只能包含数字、字母以及下划线',
    lognow: '立即登录',
    regisnot: '官网暂不支持注册，请前往APP进行注册',
    regisnotgw: '暂不支持注册，请前往APP进行注册',
    logresp: '登录成功',
    uswoerror: '用户名或密码错误',

    // entryName: '瓜子加速器',
    entryName: 'Guazi Routing',
    homePage: '首页',
    fixedPrice: '购买会员',
    Promote: '推广和赚钱',
    help: '帮助中心',
    copyright: '版权所有：@guazi Inc  地址：新加坡中央商务区莱福士广场中心布拉德利路6号',
    mine: '个人中心',

    // download: '下载瓜子VPN应用系统',
    download: '下载Guazi Routing应用系统',
    forversi: '适用于',
    versi: '操作系统',
    zai: '在',
    gethuo: '上获取安全的VPN服务，无日志',
    faster: '快速且始终连接。',
    xiazai: '下载',
    version: '版本',
    moreverdown: '更多版本下载',
    nervon: '紧张上线中',
    intercore: '核心',
    lijiziazai: '立即下载',

    dingyuejh: '请选择您的订阅计划',
    xianshi: '限时充值优惠，领取优惠券更便宜。',
    zhehe: '折合',
    yuan: '元',
    tian: '天',
    zengson: '赠送',
    shebei: '个设备',
    zuishou: '最受欢迎',
    youhuiqchec: '请选择您的优惠券',
    nindangq: '您当前有',
    keyong: '张优惠券可用',
    manjian: '满减券',
    zhekou: '折扣券',
    zhe: '折',
    youhuiq: '优惠券',
    xindtaocan: '限定套餐',
    buxian: '不限',
    jiezhitime: '截止时间',
    yongjiu: '永久有效',
    jiezhangtai: '结账台',
    zongjia: '总价',
    xianshizk: '限时折扣',
    shijizhifu: '实际支付',
    lijizhifu: '立即支付',
    qingxian: '请先',
    login: '登录',

    join: '加入我们的团队，实现百万计划',
    noyouyu: '不要再犹豫了，快来推广赚取高额佣金',
    yongjin: '我们为每一个加入我们的代理人提供了最高50%的永久佣金机制。',
    gongyi: '只要被邀用户充值，就一直会给代理人返佣，我们希望与代理人共赢。',
    baowan: '也希望有志人士，尽快与我们联系，一起实现百万计划。',
    jiaqun: '加入Telegram交流群',
    shangwu: '联系商务',

    leibie: '类别',
    jiazai: '正在加载',

    yqfangshi: '邀请方式',
    ywlianjie: '邀请链接',
    copy: '复制',
    erweima: '生成二维码',
    yqguize: '邀请规则',
    zhuyi: '注：本机或者同一台设备仅计算一次注册',
    hddaoqitime: '活动到期时间',

    shebeiguanli: '设备管理',
    zuiduo: '您当前最多可绑定',
    taishebei: '台设备',
    yichu: '移除',

    weishiyong: '未使用',
    yishiyong: '已使用',
    yiguoqi: '已过期',

    zanwu: '暂无数据',
    lijishiyong: '立即使用',

    youhuiqduih: '优惠券兑换',
    shuru: '请输入优惠券兑换码',
    lijiduihuan: '立即兑换',

    xiugaimima: '修改密码',
    shurumima: '请输入新密码',
    querenxiugai: '确认修改',
    genghuan: '更换',

    quedituichu: '确定要退出登录吗？',
    notuichu: '取消',
    queding: '确定',

    yaoqhaoy: '邀请好友',
    duihaun: '兑换',
    tuichulogin: '退出登录',


    dqshenfen: '当前身份',
    daoqitime: '到期时间',
    yidaoqi: '已到期',
    ninxuanze: '您选择了',
    taocan: '套餐',
    xuzhifu: '需支付',
    xuanzyouhuiq: '选择优惠券',

    xiazaishibai: '下载失败',
    jiazaicuowu: '加载错误',
    xgmmcg: '修改密码成功',
    caozuoshibai: '操作失败',
    caozuochengg: '操作成功',
    duihuanchengg: '兑换成功',
    duihuanshibai: '兑换失败',
    fuzhichengg: '复制成功',
    denglucehngg: '登录成功',
    userworderr: '用户名或密码输入错误',
    xiadanchengg: '下单成功',
    xiadanshibai: '下单失败',

    putong: '普通用户',
    youke: '游客',
    noCoupon: '暂无优惠券'



}