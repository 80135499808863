export const h = {
    quick: 'Quick logon Guazi Routing',
    pleasuser: 'Please enter one user name',
    pleasword: 'Please enter password',
    usererror: 'User name input error',
    worderror: 'The password length is between 8-20 digits and can only contain numbers, letters, and underscores',
    lognow: 'Log in now',
    regisnot: 'The official website currently does not support registration，Please go to the app to register',
    regisnotgw: 'Registration is currently not supported. Please go to the app to register',
    logresp: 'Login succeeded',
    uswoerror: 'Incorrect username or password',

    entryName: 'Guazi Routing',
    homePage: 'Applications',
    fixedPrice: 'Features',
    Promote: 'Promote and earn money',
    help: 'Help',
    copyright: 'Copyright: @guazi Inc Address: 6 Bradley Road, Raffles Place Centre, Central Business District, Singapore',
    mine: 'Personal Center',


    download: 'Download Guazi Routing app',
    forversi: 'for',
    versi: 'operating system',
    zai: 'Obtain secure VPN services on ',
    gethuo: '，No logs,',
    faster: 'Fast and always connected.',
    xiazai: 'Download ',
    version: ' version',
    moreverdown: 'More version downloads',
    nervon: 'Nervous online',
    intercore: 'Core',
    lijiziazai: 'Download Now',

    
    dingyuejh: 'Please select your subscription plan',
    xianshi: 'Please select your subscription plan for a limited time recharge discount, as it is cheaper to receive coupons.',
    zhehe: 'Equivalent',
    yuan: 'yuan',
    tian: 'day',
    zengson: 'give',
    shebei: 'devices',
    zuishou: 'Popular',
    youhuiqchec: 'Please select your coupon',
    nindangq: 'You currently have',
    keyong: 'coupons available',
    manjian: 'Full discount',
    zhekou: 'rebates',
    zhe: 'discount',
    youhuiq: 'coupon',
    xindtaocan: 'Limited Package',
    buxian: 'no limit',
    jiezhitime: 'deadline',
    yongjiu: 'Permanent validity',
    jiezhangtai: 'Checkout counter',
    zongjia: 'Total price',
    xianshizk: 'Limited time discount',
    shijizhifu: 'actual payment',
    lijizhifu: 'Pay',
    qingxian: 'Please first',
    login: 'login',

    join: 'Join our team to achieve the million dollar plan',
    noyouyu: "Don't hesitate anymore, come and promote to earn high commissions",
    yongjin: 'We provide a permanent commission mechanism of up to 50% for every agent who joins us.',
    gongyi: 'As long as the invited user recharges, we will always provide commission to the agent, and we hope to achieve a win-win situation with the agent.',
    baowan: 'I also hope that aspiring individuals can contact us as soon as possible and work together to achieve the million dollar plan.',
    jiaqun: 'Join Telegram communication group',
    shangwu: 'Contact Business',

    leibie: 'category',
    jiazai: 'loading',

    yqfangshi: 'Invitation method',
    ywlianjie: 'Invitation link',
    copy: 'copy',
    erweima: 'Generate QR code',
    yqguize: 'Invitation Rules',
    zhuyi: 'Note: This machine or the same device only calculates registration once',
    hddaoqitime: 'Event expiration time',

    shebeiguanli: 'equipment management',
    zuiduo: 'You can currently bind at most',
    taishebei: 'Device',
    yichu: 'remove',

    weishiyong: 'Not used',
    yishiyong: 'Used',
    yiguoqi: 'Expired',
    zanwu: 'There is currently no data available',
    lijishiyong: 'immediate use',

    youhuiqduih: 'Coupon redemption',
    shuru: 'Please enter the coupon redemption code',
    lijiduihuan: 'Redeem now',

    xiugaimima: 'Change password',
    shurumima: 'Please enter a new password',
    querenxiugai: 'Confirm modifications',
    genghuan: 'replace',

    quedituichu: 'Are you sure you want to log out？',
    notuichu: 'cancellation',
    queding: 'determine',

    yaoqhaoy: 'Invite friends',
    duihaun: 'exchange',
    tuichulogin: 'Log out',


    dqshenfen: 'Current identity',
    daoqitime: 'Expiration time',
    yidaoqi: 'Expired',
    ninxuanze: 'You have selected',
    taocan: 'Package',
    xuzhifu: 'Payment',
    xuanzyouhuiq: 'Choose a coupon',

    xiazaishibai: 'Download failed',
    jiazaicuowu: 'Load error',
    xgmmcg: 'Password changed successfully',
    caozuoshibai: 'Operation failed',
    caozuochengg: 'Operation successful',
    duihuanchengg: 'Redemption successful',
    duihuanshibai: 'Redemption failed',
    fuzhichengg: 'Replicating Success',
    denglucehngg: 'Login succeeded',
    userworderr: 'User name or password input error',
    xiadanchengg: 'Checkout success',
    xiadanshibai: 'Order placement failed',

    putong: 'Ordinary users',
    youke: 'tourist',
    noCoupon: 'There are currently no coupons available'
}