import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

document.addEventListener('contextmenu', function(e) {
    e.preventDefault();
  });

document.addEventListener('keydown', function(e) {
    if (e.key === 'F12') {
      e.preventDefault();
    }
  });  


import axios from 'axios' // axios http请求库
Vue.prototype.$http = axios

// 防止按钮重复点击
Vue.directive('preventReClick', {
    inserted(el, binding) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true
                setTimeout(() => {
                    el.disabled = false
                }, binding.value || 5000)
            }
        })
    }
});

// 使用国际化，中英文
import {
    i18n
} from './i18n/index'

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')