<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
    import { ListenWebRTCIP } from './lib/clientIP';
    export default {
        provide() {
            return {
                reload: this.reload //把方法传递给下级组件
            }
        },
        data() {
            return {
                device: '',
                isRouterShow: true //定义一个变量控制v-if
            }
        },
        created() {
            // 获取屏幕宽度
            const screenWidth = window.innerWidth;

            // 获取设备信息
            const ua = navigator.userAgent.toLowerCase();
            const url = window.location.href
            const user_exit = url.includes('?');

            if (screenWidth < 641) {
                if(user_exit){
                    let code = url.split('?')[1].split('=')[1]
                    localStorage.setItem('inv_code', code)
                    this.$router.push({ path: '/phone', query: { invite_code: code }});
                }else{
                    this.$router.push('/phone');
                }
                this.device = ua.indexOf('android') != -1 ? 1 : (ua.indexOf('iphone') != -1 ? 2 : this.device);
            } else {
                if(user_exit){
                    let code = url.split('?')[1].split('=')[1]
                    localStorage.setItem('inv_code', code)
                    this.$router.push({ path: '/puter', query: { invite_code: code }});
                }else{
                    this.$router.push('/puter');
                }

                
                
                // if (ua.indexOf('mac') != -1) {
                //     this.device = ua.indexOf('intel') !== -1 ? 3 : 4;
                // } else if (ua.indexOf('windows') != -1) {
                //     this.device = ua.indexOf('x64') !== -1 ? 5 : 6;
                // } else if (ua.indexOf('linux') != -1) {
                //     this.device = 7;
                // }

                if (ua.indexOf('mac') != -1) {
                    this.device = ua.indexOf('intel') !== -1 ? 3 : 3;
                } else if (ua.indexOf('windows') != -1) {
                    this.device = ua.indexOf('x64') !== -1 ? 5 : 5;
                } else if (ua.indexOf('linux') != -1) {
                    this.device = 7;
                }
            }

            ListenWebRTCIP((type, value) => {
                if (type === 'ipv4' || type === 'ipv6') {
                    localStorage.setItem("clien_ip", value);
                }
            });
            

            localStorage.setItem('device', this.device)
        },
        methods: {
            async reload() { //触发显示隐藏
                this.isRouterShow = false
                await this.$nextTick()
                this.isRouterShow = true
            },
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    body {
        margin: 0;
    }
</style>