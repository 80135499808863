import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/puter',
        name: 'index',
        component: () => import('../views/puter/index.vue'),
        redirect: '/puter/home',
        children: [{
                path: '/puter/home',
                name: 'home',
                component: () => import('../views/puter/home.vue'),
            },
            {
                path: '/puter/setprice',
                name: 'setprice',
                component: () => import('../views/puter/setprice.vue')
            },
            {
                path: '/puter/promotion',
                name: 'promotion',
                component: () => import('../views/puter/promotion.vue')
            },
            {
                path: '/puter/help',
                name: 'help',
                component: () => import('../views/puter/help.vue')
            },
            {
                path: '/puter/helpdetail',
                name: 'helpdetail',
                component: () => import('../views/puter/helpDetail.vue')
            },
            {
                path: '/puter/version',
                name: 'version',
                component: () => import('../views/puter/version.vue')
            },
            {
                path: '/puter/login',
                name: 'login',
                component: () => import('../views/puter/login.vue')
            },
            {
                path: '/puter/mine',
                name: 'mine',
                component: () => import('../views/puter/mine.vue'),
                redirect: '/puter/mine/invite',
                children: [{
                        path: '/puter/mine/invite',
                        name: 'invite',
                        component: () => import('../views/puter/mine/invite.vue'),
                    },
                    {
                        path: '/puter/mine/device',
                        name: 'device',
                        component: () => import('../views/puter/mine/device.vue'),
                    },
                    {
                        path: '/puter/mine/coupon',
                        name: 'coupon',
                        component: () => import('../views/puter/mine/coupon.vue'),
                    },
                    {
                        path: '/puter/mine/exchange',
                        name: 'exchange',
                        component: () => import('../views/puter/mine/exchange.vue'),
                    },
                    {
                        path: '/puter/mine/changepass',
                        name: 'changepass',
                        component: () => import('../views/puter/mine/changepass.vue'),
                    },
                    {
                        path: '/puter/mine/outlog',
                        name: 'outlog',
                        component: () => import('../views/puter/mine/outlog.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '/phone',
        name: 'index',
        component: () => import('../views/phone/index.vue'),
        redirect: '/phone/home',
        children: [{
                path: '/phone/home',
                name: 'home',
                component: () => import('../views/phone/home.vue')
            },
            {
                path: '/phone/setprice',
                name: 'setprice',
                component: () => import('../views/phone/setprice.vue')
            },
            {
                path: '/phone/poncheck',
                name: 'poncheck',
                component: () => import('../views/phone/ponCheck.vue')
            },
            {
                path: '/phone/promotion',
                name: 'promotion',
                component: () => import('../views/phone/promotion.vue')
            },
            {
                path: '/phone/login',
                name: 'login',
                component: () => import('../views/phone/login.vue')
            },
            {
                path: '/phone/mine',
                name: 'mine',
                component: () => import('../views/phone/mine.vue'),
                redirect: "/phone/device",
                children: [{
                        path: '/phone/device',
                        name: 'device',
                        component: () => import('../views/phone/device.vue')
                    },
                    {
                        path: '/phone/coupon',
                        name: 'coupon',
                        component: () => import('../views/phone/coupon.vue')
                    },
                    {
                        path: '/phone/exchan',
                        name: 'exchan',
                        component: () => import('../views/phone/exchan.vue')
                    },
                    {
                        path: '/phone/chanpass',
                        name: 'chanpass',
                        component: () => import('../views/phone/chanpass.vue')
                    }
                ]
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

// 路由重复时忽略
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router